import React, { useState } from 'react';
import Swal from 'sweetalert2';
import {
  Container,
  Content,
  DivForm,
  DivForm2,
  DivInputEmpresa,
  DivInputName,
  DivSubmitButton,
  Form,
  FormContainer,
  Input,
  InputEmail,
  Label,
  SubmitButton,
  Text,
  TextButton,
  TextContainer,
  Title,
  Title2,
} from './StyleCardHome05';

import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';

const animationVariants = {
  hidden: { x: -1000, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

function CardHome05() {
  const [name, setName] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [email, setEmail] = useState('');

  function alertError() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Complete todos os campos!',
    });
  }

  function alertSuccess() {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Email enviado com sucesso!',
    });
  }

  function sendEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (name === '' || empresa === '' || email === '') {
      alertError();
      return;
    }

    alertSuccess();

    const templateParams = {
      from_name: name,
      empresa: empresa,
      email: email,
    };

    emailjs
      .send(
        'SiteVeplex',
        'template_rzzo3ur',
        templateParams,
        'eYdC7GosxHsujkgIV'
      )
      .then(
        (response) => {
          console.log('EMAIL ENVIADO', response.status, response.text);
          setName('');
          setEmpresa('');
          setEmail('');
        },
        (err) => {
          console.log('ERRO AO ENVIAR EMAIL', err);
        }
      );
  }

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <Container ref={ref}>
      <Content>
        <TextContainer
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={animationVariants}
        >
          <Title>Fale agora com</Title>

          <Title2>nossos consultores</Title2>

          <Text>
            Entenda melhor como impulsionar seus resultados com nossas soluções
          </Text>
        </TextContainer>

        <FormContainer>
          <Form onSubmit={sendEmail}>
            <DivForm>
              <DivInputName>
                <Label>Nome</Label>
                <Input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </DivInputName>

              <DivInputEmpresa>
                <Label>Empresa</Label>
                <Input
                  type="text"
                  onChange={(e) => setEmpresa(e.target.value)}
                  value={empresa}
                />
              </DivInputEmpresa>
            </DivForm>

            <DivForm2>
              <Label>E-mail</Label>

              <InputEmail
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </DivForm2>

            <DivSubmitButton>
              <SubmitButton type="submit">
                {' '}
                <TextButton> ENVIAR AGORA </TextButton>{' '}
              </SubmitButton>
            </DivSubmitButton>
          </Form>
        </FormContainer>
      </Content>
    </Container>
  );
}

export default CardHome05;
