function PrivacyPolicyPortuguese() {
  return (
    <div>
      <h3>Política de Privacidade</h3>
      <p>
        Esta política de privacidade aplica-se ao aplicativo UP Vendas Braveo
        (doravante denominado “Aplicativo”) para dispositivos móveis que foi
        criado pela Veplex (doravante denominada “Prestadora de Serviços”) como
        um serviço comercial. Este serviço destina-se a ser utilizado "COMO
        ESTÁ".
      </p>
      <h3>Coleta e uso de informações</h3>
      <p>
        The App collects information when you download and use it. This
        information may include:
      </p>
      <ul>
        <li>
          O endereço de protocolo da Internet do seu dispositivo (por exemplo,
          endereço IP)
        </li>
        <li>
          As páginas do Aplicativo que você visita, a hora e a data da sua
          visita, o tempo gasto nessas páginas
        </li>
        <li>O tempo gasto no aplicativo</li>
        <li>O sistema operacional que você usa no seu dispositivo móvel</li>
        <li style={{ fontWeight: 'bold' }}>
          Informações de localização precisas ou aproximadas do seu dispositivo
          móvel, caso seja concedida a permissão de localização.
        </li>
      </ul>
      <p>
        O Aplicativo coleta informações de localização para melhorar a
        experiência do usuário e fornecer serviços personalizados, como
        otimização de rotas de vendas e identificação de regiões de atendimento.
        O usuário tem controle sobre o compartilhamento de dados de localização
        e pode gerenciar suas preferências nas configurações do dispositivo.
      </p>
      <h3>Informações de identificação pessoal</h3>
      <p>
        Para uma melhor experiência, ao usar o Aplicativo, o Provedor de
        Serviços pode exigir que você nos forneça certas informações de
        identificação pessoal, incluindo, mas não se limitando a:
      </p>
      <ul>
        <li>
          CNPJ, CPF, inscrição estadual, razão social, endereço residencial
          (Rua, número, bairro, Estado, Cidade)
        </li>
        <li>
          E-mail financeiro, e-mail de comunicação, nome de contato, telefone,
          WhatsApp
        </li>
        <li>Atividade, contribuinte do ICMS</li>
        <li style={{ fontWeight: 'bold' }}>
          Dados de localização, caso o usuário conceda permissão para tal.
        </li>
      </ul>
      <p>
        As informações solicitadas pelo Provedor de Serviços serão retidas por
        ele e utilizadas conforme descrito nesta política de privacidade.
      </p>
      <h3>Acesso de terceiros</h3>
      <p>
        Apenas dados agregados e anonimizados são transmitidos periodicamente a
        serviços externos para ajudar o Provedor de Serviços a melhorar o
        Aplicativo e seu serviço. O Provedor de Serviços pode compartilhar suas
        informações com terceiros nas formas descritas nesta declaração de
        privacidade.
      </p>
      <p>
        Observe que o Aplicativo utiliza serviços de terceiros que possuem sua
        própria Política de Privacidade sobre o tratamento de dados. Abaixo
        estão os links para a Política de Privacidade dos prestadores de
        serviços terceirizados utilizados pelo Aplicativo:
      </p>
      <ul>
        <li>
          <a href='https://www.google.com/policies/privacy/'>
            Google Play Services
          </a>
        </li>
        <li>
          <a href='https://firebase.google.com/support/privacy'>
            Google Analytics for Firebase
          </a>
        </li>

        <li>
          <a href='https://firebase.google.com/support/privacy/'>
            Firebase Crashlytics
          </a>
        </li>

        <li>
          <a href='https://www.sdkbox.com/privacy'>SDKBOX</a>
        </li>
      </ul>
      <p>
        O Provedor de Serviços pode divulgar Informações Fornecidas pelo Usuário
        e Coletadas Automaticamente:
      </p>
      <ul>
        <li>
          conforme exigido por lei, como para cumprir uma intimação ou processo
          legal semelhante;
        </li>
        <li>
          quando acreditam de boa fé que a divulgação é necessária para proteger
          os seus direitos, proteger a sua segurança ou a segurança de outros,
          investigar fraudes ou responder a um pedido governamental;
        </li>
        <li>
          com seus provedores de serviços confiáveis que trabalham em seu nome,
          não fazem uso independente das informações que lhes divulgamos e
          concordaram em aderir às regras estabelecidas nesta declaração de
          privacidade.
        </li>
      </ul>
      <h3>Direitos de exclusão</h3>
      <p>
        Você pode interromper facilmente toda a coleta de informações pelo
        Aplicativo desinstalando-o. Você pode usar os processos de desinstalação
        padrão que podem estar disponíveis como parte do seu dispositivo móvel
        ou através do mercado ou rede de aplicativos móveis.
      </p>
      <p>
        Se você optar por não compartilhar sua localização, poderá gerenciar
        essa permissão diretamente nas configurações do seu dispositivo. Ao
        desativar o compartilhamento de localização, algumas funcionalidades do
        Aplicativo podem ser limitadas.
      </p>
      <h3>Política de retenção de dados</h3>
      <p>
        O Provedor de Serviços reterá os dados fornecidos pelo usuário enquanto
        você usar o Aplicativo e por um período razoável a partir de então. Se
        desejar que eles excluam os Dados Fornecidos pelo Usuário que você
        forneceu por meio do Aplicativo, entre em contato pelo e-mail{' '}
        <a href='#'>contato@veplex.com.br</a> e eles responderão em um prazo
        razoável.
      </p>
      <h3>Crianças</h3>
      <p>
        O Provedor de Serviços não usa o Aplicativo para solicitar
        conscientemente dados ou comercializá-los para crianças menores de 13
        anos.
      </p>
      <p>
        O Aplicativo não se dirige a ninguém com menos de 13 anos de idade. O
        Provedor de Serviços não coleta intencionalmente informações de
        identificação pessoal de crianças com menos de 13 anos de idade. Caso o
        Provedor de Serviços descubra que uma criança menor de 13 anos forneceu
        informações pessoais, o Provedor de Serviços as excluirá imediatamente
        de seus servidores. Se você é pai ou responsável e tem conhecimento de
        que seu filho nos forneceu informações pessoais, entre em contato com o
        Prestador de Serviços (<a href='#'>contato@veplex.com.br</a>) para que
        ele possa tomar as providências necessárias.
      </p>
      <h3>Segurança</h3>
      <p>
        O Provedor de Serviços se preocupa em salvaguardar a confidencialidade
        de suas informações. O Provedor de Serviços fornece salvaguardas
        físicas, eletrônicas e processuais para proteger as informações que o
        Provedor de Serviços processa e mantém.
      </p>
    </div>
  );
}

export default PrivacyPolicyPortuguese;
