import React from 'react'
import { CardContainer, ContainerMap, ContainerText, DivText, Text, Title } from './StyleCard02'
import { A, DivIcons } from '../../footer/styleFooter'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Card02() {
    return (
        <CardContainer>
            <ContainerText>
                <DivText>
                    <Title>
                        Endereço
                    </Title>

                    <Text>
                        Avenida Sete De Setembro, 404 
                    </Text>
                    <Text>
                        Sala 8 - Passo Fundo/RS
                    </Text>
                </DivText>

                <DivText>
                    <Title>
                        Contato
                    </Title>
                    <Text>
                        (54) 99902-2203
                    </Text>
                </DivText>

                <DivText>
                    <Title>
                        Email Comercial
                    </Title>

                    <Text>
                        contato@veplex.com.br
                    </Text>
                </DivText>
            </ContainerText>

            <DivIcons style={{gap: '20px'}}>
                <A href="https://www.facebook.com/profile.php?id=100089668626255" >
                    <FacebookIcon style={{ color: 'black' }} />
                </A>

                <A href="https://www.linkedin.com/company/veplexsistemas/">
                    <LinkedInIcon style={{ color: 'black' }} />
                </A>

                <A href="https://www.instagram.com/veplexsistemas/">
                    <InstagramIcon  style={{ color: 'black' }} />
                </A>
            </DivIcons>


            <ContainerMap>
                <iframe
                    title="Localização"
                    width="100%"
                    height="400"
                    loading="lazy"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3514.1225283306235!2d-52.4037992252289!3d-28.264301275867822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94e2bf83ca108455%3A0xf2ecee36bac0ba78!2sAv.%207%20de%20Setembro%2C%20404%20-%20Centro%2C%20Passo%20Fundo%20-%20RS%2C%2099010-122!5e0!3m2!1spt-BR!2sbr!4v1737395063734!5m2!1spt-BR!2sbr"
                    style={{ border: 0 }}
                    allowFullScreen={true}
                    referrerPolicy="no-referrer-when-downgrade"
                />
            </ContainerMap>

        </CardContainer>
    )

}

export default Card02