function PrivacyPolicyEnglish() {
  return (
    <div>
      <h3>Privacy Policy</h3>
      <p>
        This privacy policy applies to the UP Vendas Braveo app (hereinafter
        referred to as the "App") for mobile devices that was created by Veplex
        (hereinafter referred to as the "Service Provider") as a commercial
        service. This service is intended to be used "AS IS."
      </p>
      <h3>Collection and use of information</h3>
      <p>
        The App collects information when you download and use it. This
        information may include:
      </p>
      <ul>
        <li>The Internet protocol address of your device (e.g., IP address)</li>
        <li>
          The pages of the App you visit, the time and date of your visit, the
          time spent on those pages
        </li>
        <li>The time spent in the app</li>
        <li>The operating system used on your mobile device</li>
        <li style={{ fontWeight: 'bold' }}>
          Precise or approximate location information from your mobile device,
          if location permission is granted.
        </li>
      </ul>
      <p>
        The App collects location information to improve the user experience and
        provide personalized services, such as route optimization for sales and
        identification of service regions. The user has control over location
        data sharing and can manage preferences in the device settings.
      </p>
      <h3>Personal identification information</h3>
      <p>
        For a better experience, while using the App, the Service Provider may
        require you to provide certain personally identifiable information,
        including but not limited to:
      </p>
      <ul>
        <li>
          CNPJ, CPF, state registration, company name, residential address
          (Street, number, neighborhood, State, City)
        </li>
        <li>
          Financial email, communication email, contact name, phone number,
          WhatsApp
        </li>
        <li>Activity, ICMS taxpayer status</li>
        <li style={{ fontWeight: 'bold' }}>
          Location data, if the user grants permission for such.
        </li>
      </ul>
      <p>
        The information requested by the Service Provider will be retained and
        used as described in this privacy policy.
      </p>
      <h3>Third-party access</h3>
      <p>
        Only aggregated and anonymized data is transmitted periodically to
        external services to help the Service Provider improve the App and its
        service. The Service Provider may share your information with third
        parties as described in this privacy statement.
      </p>
      <p>
        Please note that the App uses third-party services that have their own
        Privacy Policies regarding the handling of data. Below are the links to
        the Privacy Policies of the third-party service providers used by the
        App:
      </p>
      <ul>
        <li>
          <a href='https://www.google.com/policies/privacy/'>
            Google Play Services
          </a>
        </li>
        <li>
          <a href='https://firebase.google.com/support/privacy'>
            Google Analytics for Firebase
          </a>
        </li>

        <li>
          <a href='https://firebase.google.com/support/privacy/'>
            Firebase Crashlytics
          </a>
        </li>

        <li>
          <a href='https://www.sdkbox.com/privacy'>SDKBOX</a>
        </li>
      </ul>
      <p>
        The Service Provider may disclose User-Provided and Automatically
        Collected Information:
      </p>
      <ul>
        <li>
          as required by law, such as to comply with a subpoena or similar legal
          process;
        </li>
        <li>
          when they believe in good faith that disclosure is necessary to
          protect their rights, protect your safety or the safety of others,
          investigate fraud, or respond to a government request;
        </li>
        <li>
          with trusted service providers who work on their behalf, do not make
          independent use of the information disclosed to them, and have agreed
          to adhere to the rules set forth in this privacy statement.
        </li>
      </ul>
      <h3>Opt-out rights</h3>
      <p>
        You can easily stop all information collection by the App by
        uninstalling it. You can use the standard uninstallation processes that
        may be available as part of your mobile device or through the mobile app
        marketplace or network.
      </p>
      <p>
        If you choose not to share your location, you can manage this permission
        directly in your device settings. By disabling location sharing, some
        features of the App may be limited.
      </p>
      <h3>Data retention policy</h3>
      <p>
        The Service Provider will retain user-provided data for as long as you
        use the App and for a reasonable time thereafter. If you would like them
        to delete User-Provided Data that you have provided via the App, please
        contact <a href='#'>contato@veplex.com.br</a> and they will respond
        within a reasonable time.
      </p>
      <h3>Children</h3>
      <p>
        The Service Provider does not knowingly use the App to solicit data or
        market to children under the age of 13.
      </p>
      <p>
        The App is not directed to anyone under the age of 13. The Service
        Provider does not knowingly collect personally identifiable information
        from children under 13. In the event that the Service Provider discovers
        that a child under 13 has provided personal information, the Service
        Provider will delete such information from their servers immediately. If
        you are a parent or guardian and are aware that your child has provided
        personal information, please contact the Service Provider (
        <a href='#'>contato@veplex.com.br</a>) so they can take the necessary
        action.
      </p>
      <h3>Security</h3>
      <p>
        The Service Provider cares about safeguarding the confidentiality of
        your information. The Service Provider provides physical, electronic,
        and procedural safeguards to protect the information that the Service
        Provider processes and maintains.
      </p>
    </div>
  );
}

export default PrivacyPolicyEnglish;
