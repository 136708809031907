import React from 'react';
import './App.css';

//Config react router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//Pages
import Home from './pages/home/HomePage';
import Contato from './pages/contato/ContatoPage';
import QuemSomos from './pages/quem-somos/QuemSomosPage';
import NossasSolucoes from './pages/nossas-solucoes/NossasSolucoesPage';

//components
import Navbar from './components/navbar/Navbar';

//Styled Components
import Footer from './components/footer/Footer';
import UpPromotor from './pages/nossas-solucoes/produtos/UpPromotor/UpPromotor';
import UpVendas from './pages/nossas-solucoes/produtos/UpVendas/UpVendasPage';
import UpRotas from './pages/nossas-solucoes/produtos/UpRotas/UpRotasPage';
import PoliticaEPrivacidade from './pages/politica-de-privacidade/PoliticaEPrivacidade';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/quem-somos' element={<QuemSomos />} />
          <Route path='/nossas-solucoes' element={<NossasSolucoes />} />
          <Route path='/contato' element={<Contato />} />
          <Route path='/upvendas' element={<UpVendas />} />
          <Route path='/uppromotor' element={<UpPromotor />} />
          <Route path='/uprotas' element={<UpRotas />} />
          <Route
            path='/politica-e-privacidade-up-vendas-braveo'
            element={<PoliticaEPrivacidade />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
