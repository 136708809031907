import React from 'react';
import Card01 from './components/Card01/Card01';
import Card02 from './components/Card02/Card02';
import Card03 from './components/Card03/Card03';
import Card04 from './components/Card04/Card04';
import Card05 from './components/Card05/Card05';
import Card06 from './components/Card06/Card06';

function UpVendas() {
  return (
    <>
      <Card01 />
      <Card02 />
      <Card03 />
      <Card04 />
      <Card05 />
      <Card06 />
    </>
  );
}

export default UpVendas;
