import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #703189;
  padding-top: 40px;
  padding-bottom: 40px;

  @media (max-width: 1040px) {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 900px) {
  }

  @media (max-width: 500px) {
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TextContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 2s ease;

  @media (max-width: 1200px) {
    flex: 1;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const Title = styled.span`
  font-size: 36px;
  color: #fff;
  font-family: 'poppins', sans-serif;
  text-align: right;
  padding-bottom: 10px;
  width: 360px;

  @media (max-width: 1200px) {
    text-align: start;
  }
`;
export const Title2 = styled.span`
  font-size: 36px;
  color: #b9ce12;
  font-family: 'poppins-extralight, poppins', sans-serif;
  text-align: right;
  padding-bottom: 10px;
  width: 360px;

  @media (max-width: 1200px) {
    text-align: start;
  }
`;
export const Text = styled.span`
  font-size: 18px;
  color: #fff;
  font-family: 'open-sans', sans-serif;
  width: 330px;
  text-align: right;
  padding-top: 10px;

  @media (max-width: 1200px) {
    text-align: center;
    width: 500px;
    padding-bottom: 30px;
  }

  @media (max-width: 500px) {
    text-align: center;
    font-size: 10px;
    width: 90%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  margin-right: 1%;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const DivForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const DivInputName = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInputEmpresa = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 700px) {
    align-items: flex-start;
  }
`;

export const DivForm2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background-color: #5a1a74;
  margin-bottom: 10px;
  border: none;
  color: #fff;
  padding: 8px;
`;

export const InputEmail = styled.input`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  background-color: #5a1a74;
  margin-bottom: 10px;
  padding: 8px;
  border: none;
  color: #fff;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #fff;
  font-family: 'open-sans', sans-serif;
  width: 330px;
  margin-bottom: 4px;

  @media (max-width: 1200px) {
  }

  @media (max-width: 900px) {
  }

  @media (max-width: 500px) {
  }
`;

export const DivSubmitButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    justify-content: center;
  }
`;

export const SubmitButton = styled.button`
  width: 211px;
  height: 36px;
  background-color: #b9ce12;
  color: #000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  border: none;

  @media (max-width: 1200px) {
  }

  @media (max-width: 900px) {
  }

  @media (max-width: 500px) {
  }
`;
export const TextButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  font-family: 'open-sans', sans-serif;
  width: 330px;
  padding-bottom: 2px;

  @media (max-width: 1200px) {
  }

  @media (max-width: 900px) {
  }

  @media (max-width: 500px) {
  }
`;
