import PrivacyPolicyEnglish from '../../components/privacy-policy/PrivacyPolicyEnglish';
import PrivacyPolicyPortuguese from '../../components/privacy-policy/PrivacyPolicyPortuguese';

function PoliticaEPrivacidade() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          maxWidth: '1024px',
          padding: '0 20px',
        }}
      >
        <h1>Política e Privacidade UP Vendas Braveo</h1>
        <p>September 25, 2024</p>
        <h1>Privacy Policy in English</h1>
        <h2>(Para idioma em português role a página)</h2>
        <hr />
        <PrivacyPolicyEnglish />
        <hr />
        <PrivacyPolicyPortuguese />
      </div>
    </div>
  );
}

export default PoliticaEPrivacidade;
