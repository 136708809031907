import React, { useState } from 'react';
import {
  NavbarContainer,
  NavbarLinks,
  NavbarLogo,
  NavbarLink,
  NavbarLinkSuport,
  SuportIcon,
} from './styleNavbar';
import Dropdown from './Dropdown'; // Importe o componente Dropdown

function Navbar() {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDropdownClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <NavbarContainer>
      <NavbarLogo src={require('../../images/Logo (1).png')} alt="Logo" />
      <NavbarLinks>
        <NavbarLink to="/">Início</NavbarLink>
        <NavbarLink to="/quem-somos">Quem Somos</NavbarLink>

        {/* Adicione o Dropdown e o link "Nossas Soluções" */}
        <Dropdown isOpen={dropdownVisible} onClick={handleDropdownClick}>
          <NavbarLink to="#" onClick={handleDropdownClick}>
            Nossas Soluções
          </NavbarLink>

          {/* Adicione os links do dropdown aqui */}
          {dropdownVisible && (
            <div>
              <NavbarLink to="/upvendas">UpVendas</NavbarLink>
              <NavbarLink to="/uppromotor">UpPromotor</NavbarLink>
              <NavbarLink to="/uprotas">UpRotas</NavbarLink>
            </div>
          )}
        </Dropdown>

        <NavbarLink to="/contato">Contato</NavbarLink>
        <NavbarLinkSuport target="_blank" 
                    to="https://veplex-team.atlassian.net/servicedesk/customer/portals"><SuportIcon src={('./icon-suporte.png')}/> Suporte</NavbarLinkSuport>
      </NavbarLinks>
    </NavbarContainer>
  );
}

export default Navbar;
