import styled from "styled-components";
import { Link } from 'react-router-dom';

export const NavbarContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #eff0f3;
    width: 100%;
    height: 100px;   

    @media (max-width: 768px) {
        flex-direction: column; 
        align-items: center; 
        height: auto;
    }
`;

export const NavbarLinks = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        margin-top: 10px; 
    }
`;

export const NavbarLink = styled(Link)`
    margin-right: 15px;
    color: #8a8a8a;
    padding: 10px;
    text-decoration: none;
    transition: color 0.3s ease;
    font-family: 'open-sans', sans-serif;

    &:hover {
        color: purple;
    }

    &.active,
    &:active {
        color: lightgreen;
    }

    @media (max-width: 768px) {
        margin-right: 0; 
        margin-bottom: 10px; 
    }
`;

export const NavbarLinkSuport = styled(Link)`
    background-color:#703189;
    border-radius: 12px;
    margin-right: 15px;
    color: #ffffff;
    padding: 8px 13px;
    text-decoration: none;
    transition: color 0.3s ease;
    font-family: 'open-sans', sans-serif;

    &:hover {
        transform: scale(1.02);
    }

    @media (max-width: 768px) {
        margin-right: 0; 
        margin-bottom: 10px; 
    }
`;

export const SuportIcon = styled.img `
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin-bottom: -3px;
`;

export const NavbarLogo = styled.img`
    height: 60px;
    padding-left: 15px;
    cursor: pointer;

    @media (max-width: 768px) {
        margin-bottom: 10px; 
    }
`;
