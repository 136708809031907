import styled from "styled-components";

export const ContainerText = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 25vh;
    background-color: #b9ce12;
    padding: 2vh;
    text-align: center;
    
    letter-spacing: 2.5px;



`
export const TitleBold = styled.span `
    font-size: 25px;
    color: #1B1C1E;
    font-weight: 700;
    font-family: 'open-sans', sans-serif;

    margin-top: 5px;
    margin-bottom: 10px;

    @media (max-width: 1200px) {
        
    }   
    
    @media (max-width: 800px) {
        font-size: 24px;
        letter-spacing: 1.5px;
        margin-bottom: 15px;
        
    }

    @media (max-width: 500px) {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 20px;
    }

`;

export const Title2 = styled.span `
    font-size: 23px;
    color: #1B1C1E;
    font-weight: 400;
    font-family: 'open-sans', sans-serif;
    margin-bottom: 20px;

    @media (max-width: 1200px) {
        font-size: 22px;
        margin-bottom: 30px;
    }


    @media (max-width: 800px) {
        font-size: 22px;
        letter-spacing: 1.5px;
        line-height: 30px;
    }

    @media (max-width: 500px) {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 20px;
    }
`;

export const Title3 = styled.span `
    font-size: 18px;
    color: #1B1C1E;
    font-weight: 400;
    font-family: 'open-sans', sans-serif;
    margin-bottom: 10px;


    @media (max-width: 1200px) {
        font-size: 16px;
    }

    @media (max-width: 800px) {
        font-size: 16px;
        letter-spacing: 1.5px;
    }

    @media (max-width: 500px) {
        font-size: 10px;
        letter-spacing: 1px;
    }

`;
